.InfoPremio__table {
  color: white;
  background-color: #393f46;
  border-radius: 5px;
  width: 100%;
}

.InfoPremio__table thead {
  border-bottom: 1px solid white;
}

.InfoPremio__table th {
  padding: 1rem;
}

.InfoPremio__table td {
  padding: .25rem 1rem;
}

.InfoPremio__table tr:first-child td {
  padding-top: 1rem;
}

.InfoPremio__table tr:last-child td {
  padding-bottom: 1rem;
}
.InfoPremio__table tr th {
  font-size: 1.25rem;
}

.InfoPremio__table tr td {
  text-align: left;
}

.InfoPremio__table tr td:first-child {
  font-weight: bold;
  width: 120px;
}
