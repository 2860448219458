:root {
  --col__primary: rgba(8, 8, 141, 1);
  --col__primary_desv: rgba(8, 8, 141, 0.829);
  --col__primary_dark: #11096e;
  --col__white: rgba(238, 238, 238, 1);
  --col__white_desv: rgba(238, 238, 238, 0.829);
  --col__secondary: #ffc527;
}
::-webkit-scrollbar {
  width: 5px;
  scrollbar-width: thin;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  scrollbar-width: thin;
}
::-webkit-scrollbar-thumb {
  background-color: #7c7c7c;
}
.fondo {
  background-image: linear-gradient(50deg, #031835 7%, rgba(24, 26, 27, 0) 80%);
  background-color: initial;
}
.fondo::after {
  content: "";
  background: linear-gradient(50deg, #041e42 7%, rgba(255, 255, 255, 0) 80%);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.navbar {
  background-color: var(--col__primary_desv);
  position: fixed;
  width: 100%;
  top: 40px;
  left: 0;
  transition: 0.2s ease-in all;
  z-index: 500;
}
.navbar-info {
  background-color: #ffc107ba;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.2s ease-in all;
  z-index: 500;
  height: 40px;
  color: #fff;
  padding: 0 30px;
  text-shadow: 4px 4px 6px #000;
}
.navbar-info a {
  color: #fff;
  text-decoration: none;
  font-family: sans-serif;
}
.navbar-div {
  width: 100%;
  float: left;
  text-align: right;
  padding: 11px;
}
.navbar-div > ul {
  display: block;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}
.navbar-div > ul > li {
  display: inline-block;
  list-style-type: none;
  text-align: left;
  margin-right: 15px;
}
.navbar-div > ul > li:last-child {
  display: relative;
  background-color: transparent !important;
}
.navbar-div > ul > li.active > a,
.navbar-div > ul > li.active > button {
  background-color: var(--col__secondary);
  color: #000 !important;
  box-shadow: 0 0 0 0 var(--col__secondary);
  letter-spacing: -1px;
  animation: pulse 2s ease-out;
  animation-iteration-count: infinite;
  outline: 0;
}
.navbar-div > ul > li:active,
.navbar-div > ul > li:focus {
  user-select: none;
}
.navbar-div > ul > li.resaltador > a {
  text-transform: capitalize;
  background-color: #007bff;
  color: #fff;
  box-shadow: 0 0 0 0 #007bff;
  pointer-events: auto !important;
}
.navbar-div > ul > li.resaltador-outline > a {
  color: #fff;
  box-shadow: 0 0 0 0 #007bff;
  pointer-events: auto !important;
}
.navbar-div > ul > li.resaltador-outline:active > a,
.navbar-div > ul > li.resaltador-outline:focus > a,
.navbar-div > ul > li.resaltador-outline:hover > a,
.navbar-div > ul > li.resaltador:active > a,
.navbar-div > ul > li.resaltador:focus > a,
.navbar-div > ul > li.resaltador:hover > a,
.navbar-div > ul > li:hover > a {
  background-color: #0f71da;
  color: #fff !important;
}
.valida_error {
  color: #cf1c1c;
}
input[aria-invalid="true"],
input[aria-invalid="true"]:focus {
  border-color: #cf1c1c;
}
@keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
.navbar-div > ul > li > button {
  padding: 10px 15px !important;
  border-radius: 10px;
  text-shadow: 4px 4px 6px #000;
  font-size: 14px;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  color: #fff;
}
.navbar-div > ul > li > a {
  padding: 10px 15px !important;
  border-radius: 10px;
  text-shadow: 4px 4px 6px #000;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  pointer-events: none;
}
.navbar-div > ul > li:last-child > a {
  position: absolute;
  top: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: transparent !important;
  padding: 3px 9px !important;
  box-shadow: 1px 1px 2px #000;
}
.navbar-div > ul > li:last-child > a > i {
  font-size: 21px;
  color: #fff;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.navbar-brand > img {
  position: absolute;
  width: 80px;
  top: 20px;
}
.navbar-brand h5 {
  color: #fff !important;
  margin-left: 100px;
  font-family: sans-serif;
  font-size: 1.5rem;
  margin-top: 5px;
}
.btn-nav-sidebar {
  border: none;
  padding: 5px 10px !important;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid var(--col__secondary);
  display: none;
}
.btn-nav-sidebar > i {
  font-size: 23px;
}
.btn-nav-sidebar:active,
.btn-nav-sidebar:focus,
.btn-nav-sidebar:hover,
.navbar-div ul li a:hover {
  outline: 0;
  background-color: var(--col__secondary);
  transition: all 0.3s ease-in-out;
  color: #000;
}
#content {
  background-color: #eee;
  display: flex;
  flex-grow: 1;
  position: absolute;
  right: 0;
  margin: 0;
  padding: 0;
  padding-top: 0;
  padding-top: 0;
  margin-top: 20px;
  list-style: none;
  transition: all 0.5s ease;
  width: 57rem;
}
#content > .group {
  margin: 10px;
  padding: 10px;
  border: 1px solid #cfcfcf;
  background-color: #ddd;
  flex: 1 1 auto;
}
#content > .group:first-child {
  columns: 10em;
  flex-grow: 2;
}
#content > .group .item {
  margin: 10px;
  padding: 10px;
  background-color: #aaa;
  break-inside: avoid;
}
#content > .group .item:first-child {
  margin-top: 0;
}
@media screen and (max-width: 1024px) {
  .navbar-brand > img {
    width: 70px;
  }
  .navbar-div {
    padding: 10px;
  }
  .navbar-div ul li a {
    font-size: 23px;
  }
  .navbar-div ul li:last-child a {
    padding: 3px 11px !important;
  }
  .navbar-div ul li:last-child a > i {
    font-size: 29px;
  }
}
@media screen and (max-width: 992px) {
  .navbar-brand > img {
    width: 90px;
  }
  .navbar-div {
    padding: 0;
  }
  .navbar-div ul li a {
    font-size: 19px;
  }
  .navbar-div ul li:last-child a {
    padding: 3px 9px !important;
  }
  .navbar-div ul li:last-child a > i {
    font-size: 24px;
  }
}
@media screen and (max-width: 600px) {
  .navbar-div ul li.hide-mobile {
    display: none;
  }
  .navbar-brand > img {
    width: 70px;
  }
  .navbar-div {
    padding: 0;
  }
  .navbar-div ul li a {
    font-size: 16px;
  }
  .navbar-div ul li:last-child a {
    padding: 3px 9px !important;
  }
  .navbar-div ul li:last-child a > i {
    font-size: 21px;
  }
}
.navbar-div > ul > li > div.navdesktop_submenu {
  position: relative;
  right: 0;
}
.navbar-div > ul > li > div.navdesktop_submenu > ul {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  list-style: none;
  transition: all 0.5s ease;
  position: absolute;
  right: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-grow: 2;
}
.w31-rem {
  width: 30.8rem;
}
.navbar-div > ul > li:hover div.navdesktop_submenu > ul {
  display: flex;
  background: #fff;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0,
    rgba(255, 255, 255, 0.5074230375744048) 73%,
    rgba(255, 255, 255, 0) 100%
  );
}
.navbar-div > ul > li > a + div.navdesktop_submenu > ul {
  display: none;
}
.navbar-div > ul > li > a + div.navdesktop_submenu > ul > li {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}
.navbar-div > ul > li > a + div.navdesktop_submenu > ul > li > a {
  display: block;
  color: var(--col__primary);
  width: 200px;
  font: 700 14px "pt sans", sans-serif;
  background: var(--col__white_desv);
  border-bottom: 1px solid #ddd;
  text-align: left;
  padding: 15px;
  text-decoration: none;
  overflow: hidden;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
.navbar-div > ul > li > div.navdesktop_submenu > ul > li > a:active,
.navbar-div > ul > li > div.navdesktop_submenu > ul > li > a:focus,
.navbar-div > ul > li > div.navdesktop_submenu > ul > li > a:hover {
  background: #0d0fb9ed;
  color: #fff;
}
@media screen and (max-width: 1230px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
  }
  .navbar-div > ul > li {
    margin-right: 2px;
  }
}
@media screen and (max-width: 1025px) {
  .navbar-div > ul > li.general {
    display: none;
    visibility: hidden;
  }
  .navbar-div {
    width: auto;
  }
}
.sidebar {
  position: fixed;
  z-index: 850;
  background-color: #fff;
  top: 0;
  right: -350px;
  bottom: 0;
  padding: 20px;
  width: 350px;
  height: 100vh;
  transition: all 0.4s ease-out;
}
.sidebar.activate {
  right: 0;
  box-shadow: -2px 0 20px #000;
  transition: all 0.4s ease-out;
}
.sidebar > .navbar-brand {
  padding: 23px;
  margin-bottom: 10px;
}
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar > .lista_nav {
  overflow-y: auto;
  height: 70vh;
  padding: 10px;
}
.lista_nav li {
  margin-bottom: 5px;
}
.sidebar ul.lista_nav li a {
  display: block;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #343a40;
  font-weight: 600;
}
.lista_nav li.submenu_sidebar button:active,
.lista_nav li.submenu_sidebar button:focus,
.lista_nav li.submenu_sidebar button:hover,
.sidebar ul.lista_nav li > a:active,
.sidebar ul.lista_nav li > a:focus,
.sidebar ul.lista_nav li > a:hover {
  background-color: #0b095031;
  color: #0b0950;
  outline: 0;
}
.lista_nav li.submenu_sidebar {
  padding: 0;
}
.lista_nav li.submenu_sidebar button {
  display: block;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #343a40;
  font-weight: 600;
  width: 100%;
  border: none;
  text-align: left;
  background-color: transparent;
  margin-bottom: 5px;
  outline: 0;
}
.lista_nav li.submenu_sidebar > div > ul li a {
  display: block;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  margin-left: 20px;
}
.btn-sidebarclose {
  user-select: none;
  background-color: red;
  border-radius: 50%;
}
.btn-sidebarclose:active,
.btn-sidebarclose:focus,
.btn-sidebarclose:hover {
  outline: 0 !important;
}
.btn-sidebarclose i {
  color: #fff;
  font-size: 19px;
}
.sidebar ul.lista_nav > li.submenu_sidebar > div {
  display: block;
  border-radius: 5px;
  text-decoration: none;
  color: #343a40;
  font-weight: 600;
}
.sidebar ul.lista_nav > li.submenu_sidebar > div:active,
.sidebar ul.lista_nav > li.submenu_sidebar > div:focus,
.sidebar ul.lista_nav > li.submenu_sidebar > div:hover {
  background-color: #0b095031;
  color: #0b0950;
}
.sidebar ul.lista_nav > li:first-child {
  background-color: #0b0950;
  border-radius: 5px;
}
.sidebar ul.lista_nav > li:first-child > a {
  color: #fff;
}
.sidebar > .lista_nav::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.sidebar > .lista_nav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.sidebar > .lista_nav::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.social_nav li a {
  font-size: 25px;
  color: #0b0950;
}
.sydaisa_design {
  text-align: center;
  margin-bottom: 50px !important;
}
.sydaisa_design a {
  color: #0b0950;
  font-weight: 900;
}
@media screen and (min-width: 1025px) {
  .sidebar > .lista_nav {
    height: 65vh;
  }
  .social_nav li a {
    font-size: 28px;
  }
  .btn-sidebarclose {
    padding: 0.5rem;
  }
  .sidebar > .navbar-brand > img {
    width: 70px;
  }
}
@media screen and (max-width: 1024px) and (max-height: 600px) {
  .sidebar {
    width: 400px;
  }
  .sidebar > .navbar-brand {
    padding: 0;
    margin-bottom: 50px;
  }
  .sidebar > .navbar-brand > img {
    width: 60px;
  }
  .sidebar > .lista_nav {
    height: 63vh;
  }
  .sidebar ul.lista_nav li a {
    font-size: 12px;
  }
  .sydaisa_design {
    font-size: 20px;
  }
  .social_nav li a {
    font-size: 21px;
  }
  .sidebar > .lista_nav::-webkit-scrollbar {
    width: 4px;
  }
  .sydaisa_design {
    font-size: 16px;
  }
  .btn-sidebarclose {
    padding: 0.5rem;
  }
  .lista_nav li.submenu_sidebar button {
    font-size: 12px;
  }
}
@media screen and (max-width: 1023px) {
  .sidebar {
    width: 400px;
  }
  .sidebar ul.lista_nav li a {
    font-size: 20px;
  }
  .sydaisa_design {
    font-size: 20px;
  }
  .sidebar > .lista_nav::-webkit-scrollbar {
    width: 4px;
  }
  .btn-sidebarclose {
    padding: 0.5rem;
  }
  .lista_nav li.submenu_sidebar button {
    font-size: 12px;
  }
  .btn-nav-sidebar {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .sidebar > .navbar-brand > img {
    width: 70px;
  }
  .lista_nav li.submenu_sidebar button {
    font-size: 20px;
  }
  .btn-nav-sidebar {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .sidebar {
    width: 300px;
  }
  .sidebar > .navbar-brand {
    padding: 0;
    margin-bottom: 30px;
  }
  .sidebar > .navbar-brand > img {
    width: 60px;
  }
  .sidebar > .lista_nav {
    height: 65vh;
  }
  .social_nav li a {
    font-size: 25px;
  }
  .sidebar ul.lista_nav li a {
    font-size: 12px;
  }
  .sidebar > .lista_nav::-webkit-scrollbar {
    width: 4px;
  }
  .sydaisa_design {
    font-size: 16px;
  }
  .btn-sidebarclose {
    padding: 0.5rem;
  }
  .lista_nav li.submenu_sidebar button {
    font-size: 12px;
  }
  .btn-nav-sidebar {
    display: block;
  }
}
@media screen and (max-width: 321px) {
  .sidebar {
    width: 270px;
  }
  .sidebar > .navbar-brand {
    padding: 0;
    margin-bottom: 30px;
  }
  .sidebar > .navbar-brand > img {
    width: 55px;
  }
  .sidebar > .lista_nav {
    height: 65vh;
  }
  .sidebar ul.lista_nav li a {
    font-size: 12px;
  }
  .social_nav li a {
    font-size: 21px;
  }
  .sidebar > .lista_nav::-webkit-scrollbar {
    width: 4px;
  }
  .sydaisa_design {
    font-size: 16px;
  }
  .btn-sidebarclose {
    padding: 0.5rem;
  }
  .lista_nav li.submenu_sidebar button {
    font-size: 12px;
  }
  .btn-nav-sidebar {
    display: block;
  }
}
.sectionTitle {
  width: 100%;
  height: 10rem;
  background-color: var(--col__primary_dark);
  color: #fff;
}
.sectionTitle .titleWeb {
  height: 100%;
}
.sectionTitle .titleWeb h1 {
  font-family: sans-serif;
}
.sectionTitle > .titleWeb > .separadorSydai {
  border: 1px solid var(--col__secondary);
  width: 5%;
}
.videoPlay {
  position: absolute;
  width: 4rem;
  height: 4rem;
  z-index: 2;
  border: 1px solid #fff;
  padding: 0 8px !important;
  background-color: var(--col__primary_dark);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px !important;
  text-align: center;
  bottom: 25%;
  letter-spacing: -1px;
  animation: pulse 2s ease-out;
  animation-iteration-count: infinite;
}
@media screen and (min-width: 769px) {
  .videoPlay {
    right: 37%;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .videoPlay {
    right: 24%;
  }
}
.carousel-indicators li {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.custom-carousel-indicators li {
  background-color: #0062cc;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.card_testimonio .card {
  border: none;
  margin-bottom: 20px;
}
.width-img-45 {
  width: 45px;
}
.content__top {
  background-color: #cbd0d3;
  margin-top: -4rem;
  padding: 0 0 40px 0;
  z-index: 50;
}
ul {
  list-style: none;
}
ul > li a {
  text-decoration: none;
}
.componentListTabSidebar {
  border-radius: 20px !important;
}
.componentListTabSidebar .row {
  height: 100%;
}
.componentListTabSidebar .flex-column {
  background: #11096e;
  height: 100%;
  border: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 4%;
}
.componentListTabSidebar .flex-column a {
  color: #fff;
  padding: 13px;
}
.componentListTabSidebar .flex-column a.active {
  border: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.componentListTabSidebar h1,
.componentListTabSidebar h3 {
  font-family: sans-serif;
}
.dividerlist {
  width: 10%;
  border: 2px solid #11096e;
  margin-bottom: 2rem;
}
.componentListTabSidebar p {
  text-align: justify;
}
.nav.nav-pills .nav-link.active i,
.nav.nav-pills .show > .nav-link {
  color: #fff;
}
.nav.nav-pills .nav-link i {
  color: #3699ff;
}
.btn_sydaiclose {
  position: absolute;
  right: 0;
  margin-right: -10px;
  margin-top: -10px;
  border-radius: 50% !important;
}
.btn_dentromodal {
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.custom-list {
  padding-left: 0;
  margin-bottom: 40px;
}
.custom-list li {
  list-style: none;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
}
.custom-list li::before {
  content: "\2714";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 20px;
  top: 0;
  transform: translateX(-50%);
  background-color: #0b0950;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
}
#modal_matricula_publica .div-content {
  border-radius: 10px;
  background-color: var(--col__secondary);
  padding: 1rem;
  margin-top: 10px;
}
.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: auto;
  float: right;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #007bff;
  border: 1px solid transparent;
  box-shadow: 3px 3px 13px #007bff82;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #007bff;
  border-color: #007bff;
}
.avatar-upload .avatar-edit input + label:after {
  content: "\F02E9";
  font-family: "Material Design Icons";
  color: #fff;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 125px;
  height: 125px;
  position: relative;
  border-radius: 100%;
  border: 2px solid #3699ff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.avatar-upload-rectangle {
  position: relative;
  max-width: 100%;
}
.avatar-upload-rectangle .avatar-edit {
  position: absolute;
  right: 0;
  z-index: 1;
}
.avatar-upload-rectangle .avatar-edit input {
  display: none;
}
.avatar-upload-rectangle .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #007bff;
  border: 1px solid transparent;
  box-shadow: 3px 3px 13px #007bff82;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
}
.avatar-upload-rectangle .avatar-edit input + label:hover {
  background: #007bff;
  border-color: #007bff;
}
.avatar-upload-rectangle .avatar-edit input + label:after {
  content: "\F02E9";
  font-family: "Material Design Icons";
  color: #fff;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload-rectangle .avatar-preview {
  height: 30vh;
  position: relative;
  border: 6px solid #f8f8f8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.avatar-upload-rectangle .avatar-preview > div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #007bff;
}
.document-edit input {
  display: none;
}
.document-edit input + label {
  width: 100%;
  background: #23960e;
  border: 1px solid transparent;
  box-shadow: 3px 3px 13px #23960e82;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  padding: 0.375rem 0.75rem;
  color: #fff;
  user-select: none;
  font-size: 1rem;
  line-height: 1.2;
  border-radius: 0.25rem;
  white-space: nowrap;
  vertical-align: middle;
  height: 100%;
  align-items: center;
  display: flex;
}
.document-edit label > span {
  float: right;
}
.document-edit input + label > i {
  font-size: 1.6rem;
  color: #fff;
  margin-right: 8px;
}
.infopopover_documents {
  float: right;
  margin-bottom: 2px;
}
.wh180 {
  width: 180px !important;
  height: 180px !important;
}
.whtam-nosotros {
  width: 220px !important;
  height: 220px !important;
  user-select: none;
  pointer-events: none;
}
.custom-mark-button {
  background-color: #3333a1 !important;
  border: #3333a1;
  box-shadow: 2px 4px 8px #5e5e5e !important;
}
.modal-header {
  background: rgba(8, 8, 141, 1);
  background: -moz-linear-gradient(
    45deg,
    rgba(8, 8, 141, 1) 0,
    rgba(255, 197, 39, 1) 100%
  );
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0, rgba(8, 8, 141, 1)),
    color-stop(100%, rgba(255, 197, 39, 1))
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(8, 8, 141, 1) 0,
    rgba(255, 197, 39, 1) 100%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(8, 8, 141, 1) 0,
    rgba(255, 197, 39, 1) 100%
  );
  background: -ms-linear-gradient(
    45deg,
    rgba(8, 8, 141, 1) 0,
    rgba(255, 197, 39, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(8, 8, 141, 1) 0,
    rgba(255, 197, 39, 1) 100%
  );
  color: #fff;
}
.modal-header .close {
  padding: 0.5rem 0.5rem !important;
  margin: -5rem -3rem -1rem auto !important;
  opacity: 1 !important;
  background-color: red;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  border-radius: 50%;
}
.modal-header .close:active,
.modal-header .close:focus,
.modal-header .close:hover {
  opacity: 1 !important;
  color: #fff;
  outline: 0;
}
.login-form {
  padding: 4rem;
  border-radius: 1rem;
  box-shadow: 0 0 12px #999;
  width: 450px;
  background-color: #fff;
}
.login-content {
  background-color: #e4e6ef;
}
.help-block {
  color: #c90f0f;
  font-size: 16px;
}
.banner_global {
  height: 200px;
  padding: 20px;
  position: relative;
  padding-top: 7rem;
}
.banner_global.hit-100 {
  height: 300px !important;
}
.banner_global::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0a0119c7;
}
.banner_global h1,
.banner_global p {
  color: #fff;
  position: relative;
  user-select: none;
}
.card-custom ul {
  padding: 0;
}
.css-g1d714-ValueContainer {
  width: 12rem;
}
.anunciacionbanner,
.colegiocarousel {
  height: 100vh !important;
  display: flex;
  width: 100%;
  z-index: 1;
  top: 0;
  color: #fff;
}
.anunciacionbanner img,
.anunciacionbanner video {
  height: 100vh;
  width: 100%;
}
.custom-carousel-caption {
  position: absolute;
  width: 600px;
  height: 300px;
  z-index: 300;
  left: 10%;
  bottom: 3rem;
  font-family: sans-serif;
  text-shadow: 3px 5px 7px #000;
}
.custom-carousel-caption button {
  font-family: sans-serif;
}
.noticiastext {
  position: absolute;
  z-index: 2;
  right: 62px;
  bottom: 55vh;
  border: 1px solid #fff;
  width: 40%;
  padding: 10px;
  background-color: #fff;
  color: #000;
}
.anunciaciontext {
  padding: 50px;
  border: 10px solid #ffc107;
  position: absolute;
  z-index: 2;
  right: 62px;
  bottom: 30vh;
  border-left-color: transparent;
}
.colegioytvideo {
  position: absolute;
  z-index: 2;
  right: 43%;
  bottom: 30vh;
  border: 1px solid #fff;
  padding: 2px 13px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  box-shadow: 0 0 0 0 #ffc527;
  letter-spacing: -1px;
  animation: pulse 2s ease-out;
  animation-iteration-count: infinite;
}
.colegioytvideo i {
  font-size: 38px;
}
@media screen and (max-width: 1024px) {
  .noticiastext {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    color: #000;
    font-size: 25px;
  }
  .colegioytvideo {
    position: absolute;
    z-index: 2;
    bottom: 15vh;
    border: 1px solid #fff;
    padding: 2px 15px;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    box-shadow: 0 0 0 0 #ffc527;
    letter-spacing: -1px;
    animation: pulse 2s ease-out;
    animation-iteration-count: infinite;
  }
  .colegioytvideo i {
    font-size: 52px;
  }
}
@media screen and (max-width: 992px) {
  .noticiastext {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    color: #000;
    font-size: 20px;
  }
  .colegioytvideo {
    position: absolute;
    z-index: 2;
    bottom: 15vh;
    border: 1px solid #fff;
    padding: 2px 15px;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    box-shadow: 0 0 0 0 #ffc527;
    letter-spacing: -1px;
    animation: pulse 2s ease-out;
    animation-iteration-count: infinite;
  }
  .colegioytvideo i {
    font-size: 52px;
  }
}
@media screen and (max-width: 600px) {
  .noticiastext {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    color: #000;
    font-size: 12px;
  }
  .anunciaciontext {
    padding: 10px;
    border: 10px solid #ffc107;
    position: absolute;
    left: 0;
    right: 0;
    margin: 15px;
    width: 90%;
    z-index: 2;
    border-left-color: transparent;
  }
  .anunciaciontext h1 {
    font-size: 30px;
  }
  .colegioytvideo {
    position: absolute;
    z-index: 2;
    bottom: 15vh;
    border: 1px solid #fff;
    padding: 2px 13px;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    box-shadow: 0 0 0 0 #ffc527;
    letter-spacing: -1px;
    animation: pulse 2s ease-out;
    animation-iteration-count: infinite;
  }
  .colegioytvideo i {
    font-size: 38px;
  }
}
@keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
.sydai-container-grid {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: auto auto auto auto;
  position: relative;
  grid-column-start: span 4;
}
.sydai-grid-gap-1 {
  grid-gap: 0.1rem;
}
.sydai-grid-gap-2 {
  grid-gap: 0.2rem;
}
.sydai-grid-gap-3 {
  grid-gap: 0.3rem;
}
.sydai-grid-gap-4 {
  grid-gap: 0.4rem;
}
.sydai-grid-gap-5 {
  grid-gap: 0.5rem;
}
.sydai-grid-gap-6 {
  grid-gap: 0.6rem;
}
.sydai-grid-gap-7 {
  grid-gap: 0.7rem;
}
.sydai-grid-gap-8 {
  grid-gap: 0.8rem;
}
.sydai-grid-gap-9 {
  grid-gap: 0.9rem;
}
.sydai-grid-column {
  grid-template-columns: auto auto auto auto;
}
.sydai-container-grid img {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.sydai-container-grid > .gridscope-lg {
  grid-column-start: span 2;
  grid-row-start: span 2;
  height: 100%;
}
.sydai-container-grid > .grid.grid-single > img {
  width: 100%;
  height: 25rem;
}
.sydai-container-grid > .grid.gridscope-lg > img {
  width: 100%;
  height: 40rem;
}
.sydai-container-grid .gridscope-wdsm {
  grid-column-start: span 2;
}
.sydai-container-grid > .gridscope-wdsm > img {
  width: 100%;
  height: 20rem;
}
.sydai-container-grid .gridscope-wd100 {
  grid-column-start: span 4;
  max-height: 50vh;
}
.sydai-container-grid .gridscope-wd100 img {
  max-height: 50vh;
}
.sydai-container-grid > .gridscope-wd100 > img.rem10 {
  width: 100%;
  height: 10rem;
}
.sydai-container-grid > .gridscope-wd100 > img.rem20 {
  width: 100%;
  height: 20rem;
}
.sydai-container-grid > .gridscope-wd100 > img.rem30 {
  width: 100%;
  height: 30rem;
}
.sydai-container-grid > .gridscope-wd100 > img.rem40 {
  width: 100%;
  height: 40rem;
}
.sydai-container-grid > .grid.gridscope-sm > img {
  width: 100%;
  height: 19.8rem;
}
@media screen and (max-width: 599px) {
  .sydai-container-grid {
    grid-template-columns: none;
  }
  .testimonios p {
    font-size: 14px !important;
  }
  .videoPlay {
    bottom: 33%;
  }
  .custom-carousel-caption {
    height: auto;
    bottom: 7rem;
  }
  .custom-carousel-caption h1 {
    font-size: 1.2rem !important;
  }
  .custom-carousel-caption p {
    font-size: 12px !important;
    display: block !important;
  }
}
.sydai-container-grid > .sombraColor {
  position: relative;
}
.sydai-container-grid > .sombraColor .hover-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  transition: all 0.4s;
  background: linear-gradient(250deg, rgba(24, 92, 133, 0), #060a3c 120%);
}
.sydai-container-grid > .grid > .caption {
  position: relative;
  z-index: 1;
  font-family: sans-serif;
  text-shadow: 3px 5px 7px #000;
}
.sydai-container-grid .caption .text {
  position: absolute;
  width: 70%;
  bottom: 3rem;
  color: #fff;
  padding-left: 5%;
  background: #0a0988f0;
  padding: 9px 20px;
  margin-left: 10px;
  margin-bottom: -30px;
}
.sydai-container-grid .caption .text > p {
  font-size: 12px;
  margin-bottom: 0;
}
.sydai-container-grid .caption .text > a.btnUrl,
.sydai-container-grid .caption .text > a.video {
  text-decoration: none;
  color: #f8f400;
  font-size: 14px;
  border: 2px solid #f8f400;
  width: max-content;
  padding: 5px 10px;
  margin-top: 8px;
}
.sydai-container-grid .center-caption a {
  color: #fff;
  font-family: sans-serif;
  font-size: 1.5rem;
  padding: 2rem;
  position: absolute;
  text-shadow: 3px 5px 7px #000;
  text-decoration: none;
  z-index: 1;
  bottom: 10%;
  background: #11096ee6;
  left: 3%;
}
.sydai-container-grid .caption .text > h2 {
  margin-bottom: 0;
  font-size: 1.2rem;
}
.testimonios {
  margin: 7rem 0;
}
.testimonios p {
  font-family: sans-serif;
  font-size: 20px;
}
.footer {
  background: var(--col__primary_dark);
  color: #fff;
  width: 100%;
  margin-left: 0;
}
.footer__top {
  padding: 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.footer__top__logo {
  line-height: 40px;
}
.footer__top__logo a {
  display: inline-block;
}
.footer__top__social {
  text-align: right;
}
.footer__top__social a {
  display: inline-block;
  font-size: 15px;
  color: #fff;
  height: 40px;
  width: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  line-height: 44px;
  text-align: center;
  margin-right: 6px;
}
.footer__top__social a:last-child {
  margin-right: 0;
}
.footer__option {
  padding-bottom: 35px;
  padding-top: 75px;
}
.footer__option__item {
  margin-bottom: 35px;
}
.footer__option__item h5 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 35px;
}
.footer__option__item p {
  font-weight: 300;
  margin-bottom: 20px;
}
.footer__option__item .read__more {
  font-size: 16px;
  color: #fff;
}
.footer__option__item .read__more span {
  font-size: 16px;
  color: #fff;
  opacity: 0.5;
  position: relative;
  top: 4px;
  margin-left: 5px;
}
.footer__option__item ul {
  padding: 0;
  margin: 0;
}
.footer__option__item ul li {
  list-style: none;
}
.footer__option__item ul li a {
  color: #adadad;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
}
.footer__option__item form {
  position: relative;
}
.footer__option__item form input {
  height: 50px;
  width: 100%;
  padding-left: 20px;
  border: 1px solid #544e5e;
  background: 0 0;
  font-size: 16px;
  color: #adadad;
}
.footer__option__item form input::-webkit-input-placeholder {
  color: #adadad;
}
.footer__option__item form input::-moz-placeholder {
  color: #adadad;
}
.footer__option__item form input:-ms-input-placeholder {
  color: #adadad;
}
.footer__option__item form input::-ms-input-placeholder {
  color: #adadad;
}
.footer__option__item form input::placeholder {
  color: #adadad;
}
.footer__option__item form button {
  font-size: 20px;
  color: #fff;
  border: none;
  height: 50px;
  width: 50px;
  background: #00bfe7;
  line-height: 50px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}
.footer__copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 30px 0;
}
.footer__copyright__text {
  margin-bottom: 0;
  font-weight: 300;
}
.footer__copyright__text i {
  color: #00bfe7;
}
.footer__copyright__text a {
  color: #00bfe7;
}
.mdi-design-close {
  line-height: normal;
  color: #fff !important;
}
.table_img {
  width: 29px;
  height: 29px;
  border-radius: 50%;
}
.table_img_zoom {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  border: 1px solid #000;
}
.table_img_zoom:hover {
  transform: scale(5.5);
  transition: 0.2s ease-in;
}
.css-yk16xz-control {
  border-color: hsl(210deg 100% 61%) !important;
}
.css-1okebmr-indicatorSeparator {
  background-color: hsl(210deg 100% 61%) !important;
}
.css-tlfecz-indicatorContainer {
  color: hsl(210deg 100% 61%) !important;
}
.css-1fhf3k1-control {
  background-color: #b3b3b3d9 !important;
  border-color: #b3b3b3d9 !important;
}
.design-icon {
  margin-right: 10px;
  color: #0f107e;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
}
@media (min-width: 992px) {
  .sydai-container,
  .sydai-container-fluid,
  .sydai-container-lg,
  .sydai-container-md,
  .sydai-container-sm,
  .sydai-container-xl,
  .sydai-container-xxl {
    padding: 0 25px;
  }
}
@media (min-width: 1400px) {
  .sydai-container,
  .sydai-container-lg,
  .sydai-container-md,
  .sydai-container-sm,
  .sydai-container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .sydai-container,
  .sydai-container-md,
  .sydai-container-sm {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .sydai-container,
  .sydai-container-md,
  .sydai-container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .sydai-container,
  .sydai-container-sm {
    max-width: 540px;
  }
}
.sydai-container,
.sydai-container-fluid,
.sydai-container-lg,
.sydai-container-md,
.sydai-container-sm,
.sydai-container-xl,
.sydai-container-xxl {
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
  margin-right: auto;
  margin-left: auto;
}
.center-block {
  padding: 100px 0 30px 0;
}
.inputresponsive-login {
  padding: 3rem 5rem 1rem;
}
@media (max-width: 991.98px) {
  .sydai-container,
  .sydai-container-fluid,
  .sydai-container-lg,
  .sydai-container-md,
  .sydai-container-sm,
  .sydai-container-xl,
  .sydai-container-xxl {
    max-width: none;
    padding: 20px;
  }
  .inputresponsive-login {
    padding: 1rem;
  }
  .center-block {
    padding: 0 0 30px 0;
  }
}
.mlt-carousel {
  background: #272262;
  height: 600px;
  border-radius: 12px;
  z-index: 1;
  box-shadow: 0 0 30px grey;
}
.item-content {
  text-align: center;
  color: #fff;
}
.item-content h3 {
  font-size: 19px;
  font-weight: 700;
  text-shadow: 0 0 1px #000;
  margin: 10px 0;
}
.item-content p {
  display: block;
  font-size: 15px;
  color: #fff;
  text-shadow: 0 0 1px #000;
}
.item {
  height: 600px;
  border-radius: 12px;
  overflow: hidden;
}
.item > img {
  margin: 40px;
}
.mlt-content {
  z-index: 0;
  background: #fff;
  width: 100%;
  box-shadow: 0 15px 40px #b3b3b3;
  -webkit-border-top-right-radius: 12px;
  -webkit-border-bottom-right-radius: 12px;
  -moz-border-radius-topright: 12px;
  -moz-border-radius-bottomright: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  text-align: center;
  padding-top: 30px;
  margin-top: 17px;
  padding-bottom: 30px;
}
#myTabContent {
  margin-top: 35px;
}
.mlt-content span a {
  text-decoration: underline;
}
.mlt-content .nav-tabs {
  border: none;
  display: contents;
}
.mlt-content .tab-content {
  margin-top: 4rem;
  padding: 0 5rem;
}
.tabsnoline .tab-content {
  margin-top: 2rem;
}
@media (max-width: 991.98px) {
  .mlt-content .tab-content {
    margin-top: 4rem;
    padding: 0 2rem;
  }
}
.nav-tabs > li {
  display: inline;
  text-align: center;
  float: none;
  margin-right: -4px;
}
.mlt-content .tabbtn {
  line-height: 1.42857143;
  border: none;
  margin: 0;
  padding: 10px 20px;
  border-bottom: 1.5px solid #777;
  border-radius: 0;
  display: inline;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: #777;
  cursor: pointer;
}
@media (max-width: 991.98px) {
  .mlt-content .tabbtn {
    display: block;
  }
}
.mlt-content .tabbtn:focus,
.mlt-content .tabbtn:hover {
  border-right: none;
  border-top: none;
  border-left: none;
  background: 0 0;
}
.mlt-content .tabbtn.active,
.mlt-content .tabbtn:focus,
.mlt-content .tabbtn:hover {
  border-bottom: 2px solid #0f55bd;
  color: #0f55bd !important;
  background: 0 0;
}
.tabsnoline .tabbtn-adm {
  line-height: 1.42857143;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}
.tabsnoline .nav-tabs .nav-link {
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
}
.tabsnoline .tabbtn-adm:focus,
.tabsnoline .tabbtn-adm:hover {
  border-right: none;
  border-top: none;
  border-left: none;
  background: 0 0;
}
.tabsnoline .tabbtn-adm.active,
.tabsnoline .tabbtn-adm:focus,
.tabsnoline .tabbtn-adm:hover {
  border-bottom: 2px solid #0f55bd;
  color: #0f55bd !important;
  background: 0 0;
}
.termsLabel {
  margin-bottom: 5px;
  margin-top: 15px;
}
.lt-register-btn {
  background: #0f55bd;
  color: #fff;
  border-radius: 30px;
  text-transform: uppercase;
  padding: 12px 65px 12px 50px;
  line-height: normal;
  font-size: 16px;
  position: relative;
  margin-top: 35px;
}
.lt-register-btn:focus,
.lt-register-btn:hover {
  color: #fff;
  background: #11cc4a;
}
.lt-register-btn i {
  position: absolute;
  right: 15px;
}
@media (max-width: 768px) {
  .mlt-content {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
  }
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.active.right,
  .carousel-fade .carousel-inner > .item.next {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.active.left,
  .carousel-fade .carousel-inner > .item.prev {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.active,
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.access-point-off > i {
  font-size: 4rem;
  color: #e81111;
}
.dropdown-item-color-red {
  color: red;
}
.dropdown-item {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  line-height: 1;
}
.paso-dropdown {
  display: none;
}
.paso-dropdown.active {
  display: block;
}
.btn-custom-video {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.7rem;
}
@media screen and (max-width: 769px) {
  div.header-mobile + div > div.page > div:first-child {
    display: none;
  }
}
.custom-input {
  border: 0;
  border-bottom: 2px solid #000;
  border-radius: 0;
  font-size: 1.3rem;
  padding: 1.7rem 1rem;
  background: #fff;
}
input.custom-input + i {
  font-size: 2.1rem;
  color: #000;
}
.tilt-in-fwd-tl {
  -webkit-animation: tilt-in-fwd-tl 0.9s cubic-bezier(0.55, 0.055, 0.675, 0.19)
    both;
  animation: tilt-in-fwd-tl 0.9s cubic-bezier(0.55, 0.055, 0.675, 0.19) both;
}
@-webkit-keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
.slide-in-elliptic-bottom-fwd {
  -webkit-animation: slide-in-elliptic-bottom-fwd 1.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-bottom-fwd 1.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
    transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
    transform-origin: 50% -1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
    transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
    transform-origin: 50% -1400px;
    opacity: 1;
  }
}
.slide-in-left {
  -webkit-animation: slide-in-left 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: slide-in-left 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.popover {
  box-shadow: 0 2px 20px #000;
}
.breadcrumb-head {
  margin-top: 0;
  margin-bottom: 0;
  padding: 4px 10px;
  color: #000;
}
.content-student .mlt-content .tab-content {
  padding: 0 2rem;
}
.card,
.content-student .mlt-content {
  border-radius: 2rem;
}
.card-custom {
  border: 2px solid #524e8acc;
}
.card-custom .card-header-custom {
  border-bottom: 1px solid #27207f;
  padding: 1.4rem;
  font-weight: 700;
  background-color: #100e8a;
  color: #fff;
}
.card-custom .card-body-custom ul li {
  padding: 0.7rem 1rem;
  font-weight: 500;
  border-bottom: 1px solid #27207f;
}
.card-custom
  .card-body-custom
  ul
  li:hover
  .card-custom
  .card-body-custom
  ul
  li:focus
  .card-custom
  .card-body-custom
  ul
  li:active
  .card-custom
  .card-body-custom
  ul
  li.active {
  background-color: #d1cffb;
  cursor: pointer;
}
.list_video_public {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.list_video_public li {
  width: 18rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 100%;
}
.text-nombre-cuenta {
  font-weight: 700;
  font-size: 1.2rem;
}
.bg-fondo {
  background-color: #e2e4ed;
}
.bg-celeste {
  background-color: #d1e8ff !important;
}
.fz-1_6rem i {
  font-size: 1.6rem !important;
}
.card.card-custom.card-stretch {
  height: auto;
}
.posabs-icon {
  position: absolute;
  right: -8px;
  top: -13px;
  font-size: 2rem;
  color: #fff;
}
.stylevideo-message {
  font-size: 15px;
  width: 22rem;
  height: 14rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.open_nuvmodalcorreo {
  display: block;
  padding: 17px;
}
.cursorPointer {
  cursor: pointer !important;
}
.parent-expand-foo {
  background-color: #007bff !important;
  color: #fff !important;
}
.react-bootstrap-table .row-expansion-style {
  padding: 2px !important;
}
.bgc-email {
  background: #1e1e2d;
  color: #fff;
}
.bgc-email .btn.btn-clean i {
  color: #b1b1b1;
}
.page-numbers li {
  padding: 0.6rem;
  border-radius: 0.4rem;
  margin-right: 3px;
  color: #0062cc;
  cursor: pointer;
  user-select: none;
}
.page-numbers li.active,
.page-numbers li:active,
.page-numbers li:focus,
.page-numbers li:hover {
  background-color: #0062cc;
  color: #fff;
}
.page-numbers li.disabled {
  background-color: #bbb;
  color: #757575;
  cursor: context-menu;
}
.react-bootstrap-table table {
  table-layout: auto !important;
}
.expandRow-table-custom {
  border-bottom: 1px solid #abaeb9;
  padding-bottom: 6px;
  padding-left: 1.25rem;
  padding-top: 0.75rem;
}
.expandRow-table-custom-ult {
  padding-left: 1.25rem !important;
  padding-top: 0.75rem;
}
.respcorreo-date {
  position: absolute;
  right: 0;
  margin-right: 2rem;
}
.number-row {
  background-color: #62b1f5;
}
